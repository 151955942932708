<template>
    <div v-if="isLandscape" class="landscape-warning-container">
      <div class="landscape-warning">
        <p>Por favor, rotacione seu dispositivo para o modo retrato.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LandscapeWarning',
    data() {
      return {
        isLandscape: false
      }
    },
    mounted() {
      this.checkOrientation();
      window.addEventListener('orientationchange', this.checkOrientation);
    },
    beforeUnmount() {
      window.removeEventListener('orientationchange', this.checkOrientation);
    },
    methods: {
      checkOrientation() {
        this.isLandscape = window.orientation === 90 || window.orientation === -90;
      }
    }
  }
  </script>
  
  <style scoped>
  .landscape-warning-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  .landscape-warning {
    color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
  }
  </style>