<template>
    <div class="welcomeSign">
        <img class="custom-index" :src="SignImage" alt="Welcome Sign" />
        <div >
            <img class="subSign sign" :src="SignImage2" alt="Welcome Sign" />
            <p class="subSignText sign">Data <br/> 19/10</p>
        </div>
        <div class="logoOverlay"><img :src="Logo1" alt="Welcome Sign" /></div>
    </div>
</template>
<script>
import SignImage from '@/assets/Sign.png';
import SignImage2 from '@/assets/Sign2.png';
import Logo1 from '@/assets/Logo1.png';
export default {
    name: 'WelcomeSign',
    data() {
        return {
            SignImage,
            SignImage2,
            Logo1
        }
    }
}
</script>
<style lang="css" scoped>
.welcomeSign {
    position: absolute;
    top: -50px; /* Ajuste conforme necessário para posicionar a imagem fora da tela */
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 2;
    font-family: 'Tilt Warp', sans-serif; /* Aplicar a fonte aqui */
}
.welcomeSign img {
    width: 100%; /* Ajuste conforme necessário para aumentar o tamanho da imagem */
    max-width: 130%; /* Garantir que a imagem não seja limitada pelo contêiner */
    height: auto; /* Manter a proporção da imagem */
}
.subSign {
    position: absolute;
    top: 70vw;
    left: 30%;
    transform: translateX(-50%);
    width: 40% !important;
    height: auto;
    z-index: 1;
}

.subSignText {
    position: absolute;
    top: 87%;
    left: 40%;
    transform: translateX(-50%);
    height: auto;
    z-index: 2;
    font-size: 7vw;
    line-height: 30px;
    text-align: center;
    color: #FFE343;
    /* Pinta ao redor da letra de azul */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #4978A6;
}

.custom-index {
    z-index: 2;
}

.sign {
  animation: sway 3s ease-in-out infinite;
}

@keyframes sway {
  0%, 100% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(3deg);
  }
}

.logoOverlay {
    position: absolute;
    top: 47vw;
    right: 85px;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 2;
    font-family: 'Tilt Warp', sans-serif; /* Aplicar a fonte aqui */
}
</style>