<template>
  <BaseBackground >
      <WelcomeSign />
      <Footer @open-modal="openModal" />
      <ConfirmationModal ref="confirmationModal" />
  </BaseBackground>
</template>

<script>
import BaseBackground from '@/components/BaseBackground.vue';
import WelcomeSign from '@/components/WelcomeSign.vue';
import Footer from '@/components/Footer.vue';
import ConfirmationModal from '@/components/ConfirmationModal.vue';

export default {
  components: {
    BaseBackground,
    WelcomeSign,
    Footer,
    ConfirmationModal
  },
  methods: {
    openModal() {
      this.$refs.confirmationModal.openModal();
    }
  }
}
</script>

<style lang="css" scoped>
/* Estilos específicos para a HomeView */
/* desative o scroll do site */
.no-overflow {
  overflow: hidden;
}
</style>
