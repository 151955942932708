<template>
  <div id="app">
    <LoadingSpinner :isLoading="isLoading" />
    <DeviceWarning />
    <LandscapeWarning />
    <div v-if="!isDesktopOrTablet && !isLoading">
      <router-view/>
    </div>
  </div>
</template>

<script>
import DeviceWarning from '@/components/DeviceWarning.vue';
import LandscapeWarning from '@/components/LandscapeWarning.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: 'App',
  components: {
    DeviceWarning,
    LandscapeWarning,
    LoadingSpinner
  },
  data() {
    return {
      isDesktopOrTablet: false,
      isLoading: true
    }
  },
  mounted() {
    this.checkDevice();
    window.addEventListener('resize', this.checkDevice);
    this.preloadImages();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkDevice);
  },
  methods: {
    checkDevice() {
      this.isDesktopOrTablet = window.innerWidth > 768; // Ajuste o tamanho conforme necessário
    },
    preloadImages() {
      const images = [
        require('@/assets/Logo1.png'),
        require('@/assets/Background.png'),
        // Adicione outras imagens que precisam ser pré-carregadas
      ];
      let loadedImages = 0;
      images.forEach(src => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          loadedImages++;
          if (loadedImages === images.length) {
            this.isLoading = false;
          }
        };
      });
    }
  }
}
</script>

<style>
#app {
  font-family: 'Tilt Warp', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>