<template>
    <div class="footer">
      <button class="floating-button" @click="$emit('open-modal')">Confirmar Presença</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FooterSign'
  }
  </script>
  
  <style scoped>
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(232, 142, 104, 0.3);
    color: white;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 5;
  }
  .floating-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #E88E68;
    color: white;
    border: none;
    border-radius: 25px;
    width: 300px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 5 !important;
    font-size: 25px;
  }
  </style>