<template>
    <div v-if="isDesktopOrTablet" class="device-warning-container">
        <div class="device-warning">
            <p>Visualização apenas para celulares</p>
            <p>Por favor, acesse este site em um dispositivo móvel</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeviceWarning',
    data() {
        return {
            isDesktopOrTablet: false
        }
    },
    mounted() {
        this.checkDevice();
        window.addEventListener('resize', this.checkDevice);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkDevice);
    },
    methods: {
        checkDevice() {
            this.isDesktopOrTablet = window.innerWidth > 768; // Ajuste o tamanho conforme necessário
            if (this.isDesktopOrTablet) {
                document.body.style.backgroundColor = '#E88E68';
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.backgroundColor = '';
                document.body.style.overflow = '';
            }
        }
    }
}
</script>

<style scoped>
.device-warning-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #E88E68;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.device-warning {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
}
</style>