<template>
  <transition name="modal-slide-up">
    <div
      v-if="isVisible"
      class="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50"
    >
      <div
        class="bg-[#E88E68] rounded-lg w-full max-w-lg relative h-[90vh] mt-10 flex flex-col"
      >
        <!-- Header -->
        <div
          class="bg-black bg-opacity-50 p-4 w-full flex justify-between items-center"
          style="width: 100%"
        >
          <img
            src="@/assets/Logo1.png"
            alt="Close"
            class="custom-img"
            @click="closeModal"
          />
          <button class="text-4xl toy-font" @click="closeModal">X</button>
        </div>
        <!-- Main Content -->
        <div class="flex-grow overflow-auto p-4 custom-ptop">
          <form @submit.prevent="submitForm" class="">
            <div class="mb-4">
              <p for="name" class="text-5xl pb-1 toy-font">Nome</p>
              <input
                type="text"
                id="name"
                v-model="formData.name"
                required
                placeholder="ex: Heitor Carias"
                class="w-full p-2 text-[#303030]  border rounded h-12 bg-[#F9F2C1] custom-shadow"
              />
            </div>
            <div class="mb-4">
              <p for="email" class="text-5xl pb-1 toy-font">Telefone</p>
              <input
                type="text"
                id="phone"
                v-model="formData.phone"
                v-mask="'(##) #####-####'"
                placeholder="ex: (21) 99999-9999"
                required
                class="w-full text-[#303030] p-2 border rounded h-12 bg-[#F9F2C1] custom-shadow"
                inputmode="numeric"
                pattern="[0-9]*"
              />
            </div>
          </form>
          <p class="text-[#F9F2C1]">
            *Caso precise confirmar multiplas presenças, confirme uma de cada
            vez.
          </p>
        </div>
        <!-- Submit Button -->
        <div class="p-4 w-full flex justify-center">
          <button
            type="submit"
            class="bg-[#E3D357] text-[#4878A6] text-2xl py-4 px-4 rounded-full w-4/5 mx-auto custom-shadow"
            @click="submitForm"
            :disabled="loading"
          >
            <span v-if="loading">Confirmando...</span>
            <span v-else>Confirmar Presença</span>
          </button>
        </div>
        <!-- Footer -->
        <div
          class="bg-black bg-opacity-50 p-4 pb-10 w-full text-center text-2xl"
        >
          <p class="text-[#EDE159]">Local: Rua Dr. Luis bicalho 293</p>
          <p class="text-[#EDE159]">Bairro: Rocha Miranda</p>
          <p class="text-[#EDE159]">Dia: 19 de Outubro</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mask } from "vue-the-mask";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "ConfirmationModal",
  directives: { mask },
  data() {
    return {
      isVisible: false,
      loading: false,
      formData: {
        name: "",
        phone: "",
      },
    };
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    validatePhone(value) {
      return value.length === 15;
    },
    clearFields() {
      this.formData.name = "";
      this.formData.phone = "";
    },
    async submitForm() {
      if (!this.formData.name || !this.formData.phone) {
        Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Por favor, preencha todos os campos.",
          background: "#F9F2C1",
          confirmButtonColor: "#E88E68",
        });
        return;
      }
      if (!this.validatePhone(this.formData.phone)) {
        Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Por favor, preencha o telefone corretamente.",
          background: "#F9F2C1",
          confirmButtonColor: "#E88E68",
        });
        return;
      }

      this.loading = true;

      Swal.fire({
        title: "Confirmando...",
        text: "Por favor, aguarde.",
        background: "#F9F2C1",
        showConfirmButton: false,
        allowOutsideClick: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        // Simulação de request com axios
        await axios.post("https://us-central1-debugdevelopment-e6e0c.cloudfunctions.net/api/confirm/user", {
          name: this.formData.name,
          phone: this.formData.phone,
        });
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Presença confirmada com sucesso!",
          background: "#F9F2C1",
          confirmButtonColor: "#E88E68",
        }).then(() => {
          this.clearFields();
          this.closeModal();
          this.loading = false;
          Swal.close();
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Ocorreu um erro ao confirmar a presença. Tente novamente.",
          background: "#F9F2C1",
          confirmButtonColor: "#E88E68",
        }).then(() => {
          this.clearFields();
          this.loading = false;
          Swal.close();
        });
      }
    },
  },
};
</script>

<style scoped>
.modal-slide-up-enter-active,
.modal-slide-up-leave-active {
  transition: transform 0.5s, opacity 0.5s;
}
.modal-slide-up-enter, .modal-slide-up-leave-to /* .modal-slide-up-leave-active em versões anteriores do Vue */ {
  transform: translateY(50%);
  opacity: 0;
}
.custom-shadow {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
.custom-ptop {
  padding-top: 10vh;
}
.custom-img {
  width: 100px;
  height: 50px;
  object-fit: cover;
}
.custom-img:hover {
  cursor: pointer;
}
</style>