<template>
    <div class="heitorBackgroung">
       <div class="overlay"></div>
       <slot></slot>
    </div>
 </template>
 
 <script>
 export default {
     name: 'BaseBackground'
 }
 </script>
 
 <style lang="css" scoped>
 .heitorBackgroung {
     position: relative;
     background-image: url('../assets/Background.png');
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
     height: 100vh;
     width: 100vw;
     display: flex;
     justify-content: center;
     align-items: center;
 }
 
 .overlay {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: rgba(0, 0, 0, 0.4); /* Ajuste a opacidade conforme necessário */
     z-index: 1;
 }
 
 ::v-deep > .heitorBackgroung > *:not(.overlay) {
     position: relative;
     z-index: 2;
 }
 </style>